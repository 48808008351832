html,
body {
  margin: 0;
  font-family: 'inter';
  scroll-behavior: smooth;

  p {
    margin: 0;
  }

  div {
    display: flex;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #7f7f7f;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #bf2f76;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bf2f76;
}

.ant-card {
  flex-direction: column;
}

.ant-notification {
  .ant-notification-notice {
    .ant-notification-notice-content {
      flex-direction: column;

      .ant-notification-notice-with-icon {
        flex-direction: column;
      }
    }
  }
}

.ant-image {
  .ant-image-mask-info {
    display: block;
  }
}

.ant-image-preview-wrap {
  display: block;
  width: 100%;
  height: 100%;

  .ant-image-preview {
    display: block;

    .ant-image-preview-body {
      display: block;

      .ant-image-preview-img {
        max-width: 90%;
        max-height: 90%;
      }
    }
  }
}

.ant-image-preview-operations-wrapper {
  display: block;
}

.gm-style {
  div>* {
    display: block;
  }
}

.react-photo-gallery--gallery {
  display: block;

  div {
    display: block;

    .ant-image {
      margin: 2px;
      overflow: hidden;
      position: relative;
    }
  }
}

@font-face {
  font-family: 'roboto';
  font-display: swap;
  src: url('../fonts/roboto-mono.ttf') format('ttf');
}

@font-face {
  font-family: 'inter';
  font-display: swap;
  src: url('../fonts/inter.ttf') format('ttf');
}